import React, {useState} from 'react'
import cn from 'classnames'
// import { NavLink } from 'react-router-dom'
import styles from './Box.module.sass'
import Select from 'react-select'
import TextInput from '../TextInput'
import config from '../../config'
import Caller from '../../service/caller'

const Panel = ({ competitions }) => {
  const [competition, setCompetition] = useState()
  const [selected, setSelected] = useState()
  const [pin, setPin] = useState('')
  const [error, setError] = useState()

  // Extract leagues from competitions list
  const leagues = competitions.reduce((acc, c) => {
    if (!acc.includes(c.league)) {
      acc.push(c.league);
    }
    return acc;
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!pin.match(/[0-9]{6}/)) {
      setError('Le code pin doit etre compose de 6 chiffres')
      return false
    }
    Caller.authPin(`${config.api_path}/auth/sign-in/referee`, {
      competition,
      pin
    }).then(r => {
      setError(undefined)
      const object = competitions.find( c => {
        return c._id === competition
      })

      localStorage.setItem('competition', JSON.stringify(object))
      localStorage.setItem('arbitre-token', r.token)
      localStorage.setItem('serie', r.series)
      localStorage.setItem('pin', pin)
      window.location.href = '/poste-tireur'
    }).catch(error => {
      setError('Le code PIN saisi n\'est pas valide !')
    })
  }

  return (
    <div
      style={{float: 'left', width: '100%', color: 'black'}}
      className={cn(styles.panel)}
    >
      <div
        style={{ height: '270px', padding: '30px', color: 'black', backgroundColor: 'white !important' }}
        className={styles.background}
      >
        <div style={{ width: '100%', height: '200px', textAlign: 'center' }}>
          <form className={cn('auth-pin', styles.authpin)}>
            <div style={{marginBottom: '10px'}}>
              <Select
                placeholder="Selectionner la ligue"
                onChange={(e) => {
                  setSelected(e.value)
                  setCompetition(undefined)
                }}
                options={leagues.map(l => ({
                  label: l, value: l
                }))}
              />
            </div>

            {selected && (
              <>
                <Select
                  key={selected}
                  placeholder="selectionner un competition"
                  onChange={(e) => setCompetition(e.value)}
                  value={competition?._id}
                  options={competitions.filter(c => c.league === selected).map((c) => {
                    return { value: c._id, label: c.name }
                  })}
                />
                {competition && (
                  <>
                    <p style={{fontWeight: 'bold', padding: '5px'}}>Puis siaisissez votre code PIN</p>
                    <TextInput
                      type="number"
                      inputMode="numeric"
                      name="password"
                      style={{textAlgin: 'center'}}
                      onChange={(e) => setPin(e.target.value)}
                    />

                    {error && (
                      <p style={{color: 'red'}}>{error}</p>
                    )}

                    <button
                      className={cn('button', styles.button)} disabled={pin.length !== 6}
                      style={{ textAlign: 'center', marginTop: '10px' }}
                      onClick={ onSubmit }
                    >Se connecter</button>
                  </>
                )}
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Panel
